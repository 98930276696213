import { signInWithEmailAndPassword, connectAuthEmulator } from 'firebase/auth';
import { auth } from './modules/firebase/firebaseInit.js';

document.getElementById('togglePassword').addEventListener('change', (e) => {
  const passwordInput = document.getElementById('password');
  passwordInput.type = e.target.checked ? 'text' : 'password';
});

if (import.meta.env.VITE_USE_FIRESTORE_EMULATOR === 'true') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get elements
const loginButton = document.getElementById('login');

// Add login event
loginButton.addEventListener('click', (e) => {
  e.preventDefault();
  const emailInput = document.getElementById('email');
  const passwordInput = document.getElementById('password');

  const email = emailInput.value;
  const password = passwordInput.value;
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const { user } = userCredential;
      // Store user in local storage
      window.localStorage.setItem('user', JSON.stringify(user));
      // Redirect to dashboard
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirect') || '/devices';
      window.location.href = redirectUrl;
      // ...
    })
    .catch((error) => {
      /**
       * @type {Record<string, string>}
       */
      const errorCodeMessage = {
        'auth/too-many-requests': 'Too many requests. Try again later.',
        'auth/invalid-credential': 'Invalid username or password.',
      };

      const errorMessage = errorCodeMessage[error.code] || error.message;
      document.getElementById('error-message').textContent = errorMessage;
      // set error message display to visible
      document.getElementById('error-message').style.display = 'block';
      Sentry.captureException(error);
    });
});

// // Add send email link event
// const sendEmailLinkButton = document.getElementById('sendEmailLink');

// sendEmailLinkButton.addEventListener('click', (e) => {
//   e.preventDefault();
//   const email = emailInput.value;
//   firebase.auth().sendSignInLinkToEmail(email, {
//     url: 'https://www.example.com/finishSignUp?cartId=1234', // Replace with your URL
//     handleCodeInApp: true,
//   })
//     .then(() => {
//       window.localStorage.setItem('emailForSignIn', email);
//     })
//     .catch((error) => {
//       Sentry.captureException(error);
//       const errorCode = error.code;
//       const errorMessage = error.message;
//     });
// });
